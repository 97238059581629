import "core-js/modules/es.array.join"; //
//
//
//
//
//
//
//
//
//
//
//
//
//

import { code2Text } from './index.vue';

export default {
  name: 'codeArea',
  components: {},
  props: {
    code: {
      type: Array,
      "default": function _default() {return [];} },

    detail: {
      type: String,
      "default": '' } },


  data: function data() {
    return {};
  },
  computed: {
    area: function area() {
      return code2Text(this.code).join('');
    } },

  methods: {} };